import React, { useEffect } from 'react';
import { useInView } from 'react-intersection-observer';
import { motion, useAnimation } from 'framer-motion';

import { fadeIn, slideUp } from './indexVariants';
import * as welcomeStyles from './welcomepodcast.module.scss';


 const Podcast = () =>  {
  const [ref, isVisible] = useInView({ threshold: 0.6 });
  const controls = useAnimation();
// styles


  useEffect(() => {
    if(isVisible)
      controls.start('animate');
  }, [isVisible, controls])

  return (
  	<section ref={ref}  className={welcomeStyles.backgroundbio}>
      <section ref={ref}  className={welcomeStyles.effectcolor}>
   	  <section ref={ref}  className={welcomeStyles.container}>
      
	  
       <div className={welcomeStyles.content}>
       <div class="container-fluid"> 
         <div class="row">
           <div class="col-lg-4 col-md-12">
            <img src="/static/Heres-the-thing-phone-25a068224fc3436abe652c1518605954.png" class={welcomeStyles.imagen} />              
           </div>
           <div class="col-lg-8 col-md-12">
           <h1>
        HERE'S THE THING WITH ALEC BALDWIN
      </h1>
              <p>Several years ago, I began a dialogue with a few different radio platforms about an idea I had for a podcast. The back and forth at that time, discussing different formats, styles, some funny, some serious, resulted in my show HERE’S THE THING.</p>
              <p>The style of HERE’S THE THING is obviously quite straightforward: we book guests from entertainment, politics, artists, writers, academics, musicians, and discuss their careers with them, with an emphasis on origin stories. For example, how did Carly Simon become Carly Simon? It’s safe to say that nearly all my guests are people that I outright admire or, at least, have a significant degree of interest in their work. </p>
              <p>To date, we now have over 250 shows in our archives, with guests as diverse as Thom York, Gay Talese, Erica Jong, and Julianne Moore. The show has provided me with an opportunity to exercise my curiosity about those people who have either scaled the heights of their profession or made some contribution to society through their work.</p>
              <p>Although finding time to do the show has been difficult, now and then, I enjoy the experience immensely. I hope you will too. Recently, we moved the show from public radio to iHeart Radio. You can find episodes of HERE’S THE THING at this <a  href="http://heresthething.org">link</a>. I hope you’ll tune in and thank you.</p>
              <p></p>
              <p></p>
              <p></p>
              <p></p>
              
           </div>
         </div>
        
         <div class="row">
            <div class="col-md-12" className={welcomeStyles.containeriframe}>
            <video className={welcomeStyles.iframe}  controls="true">
                <source src="https://media.sparemin.com/embed-video/videos/7a/b8/2e/6d/7ab82e6d-6344-44d1-91b4-3a6bd1ce578c_7286189.mp4?rel=0" type="video/mp4" />
              </video>
            <video className={welcomeStyles.iframe}  controls="true">
                <source src="https://media.sparemin.com/embed-video/videos/24/e0/65/48/24e06548-f7c3-4013-aa58-adac2845138d_6779606.mp4?rel=0" type="video/mp4" />
              </video>
            <video className={welcomeStyles.iframe}  controls="true">
                <source src="https://media.sparemin.com/embed-video/videos/57/62/a0/02/5762a002-d4d1-497c-beb6-272b07fb8f06_6707433.mp4?rel=0" type="video/mp4" />
              </video>
            <video className={welcomeStyles.iframe}  controls="true">
                <source src="https://media.sparemin.com/embed-video/videos/7c/ca/9d/a7/7cca9da7-6049-48d5-a1b0-6119a8b5f441_6644796.mp4?rel=0" type="video/mp4" />
              </video>
            <video className={welcomeStyles.iframe}  controls="true">
                <source src="https://media.sparemin.com/embed-video/videos/b9/cb/76/b1/b9cb76b1-6085-4033-9556-a79d60ca8e9e_6594123.mp4?rel=0" type="video/mp4" />
              </video>
            <video className={welcomeStyles.iframe}  controls="true">
                <source src="https://media.sparemin.com/embed-video/videos/52/a9/c3/e3/52a9c3e3-725a-4ee0-90b3-c21b05be9d02_6549338.mp4?rel=0" type="video/mp4" />
              </video>
            <video className={welcomeStyles.iframe}  controls="true">
                <source src="https://media.sparemin.com/embed-video/videos/ca/39/cc/b1/ca39ccb1-64d3-4b42-9c40-68db916cf42e_6498643.mp4?rel=0" type="video/mp4" />
              </video>
            <video className={welcomeStyles.iframe}  controls="true">
                <source src="https://media.sparemin.com/embed-video/videos/8a/42/95/d2/8a4295d2-a8bf-44b7-93a8-be262d7855e2_6498603.mp4?rel=0" type="video/mp4" />
              </video>
            <video className={welcomeStyles.iframe}  controls="true">
                <source src="https://media.sparemin.com/embed-video/videos/49/a1/d4/04/49a1d404-a8e4-48c7-8439-412ed128797d_6444727.mp4?rel=0" type="video/mp4" />
              </video>
            <video className={welcomeStyles.iframe}  controls="true">
                <source src="https://media.sparemin.com/embed-video/videos/94/dd/5d/26/94dd5d26-54d1-4911-b1d9-a2c6ddd4c3c4_6210314.mp4?rel=0" type="video/mp4" />
              </video>

              <video className={welcomeStyles.iframe}  controls="true">
                <source src="https://media.sparemin.com/embed-video/videos/2c/f2/7d/36/2cf27d36-d1fb-4edf-aae2-dd3e422e0ec7_6210257.mp4?rel=0" type="video/mp4" />
              </video>
            <video className={welcomeStyles.iframe}  controls="true">
                <source src="https://media.sparemin.com/embed-video/videos/63/0e/b7/ef/630eb7ef-4203-4888-978c-36c0f1822d63_6087100.mp4?rel=0" type="video/mp4" />
              </video>
              <video className={welcomeStyles.iframe}  controls="true">
                <source src=" https://media.sparemin.com/embed-video/videos/62/a2/41/3f/62a2413f-0201-4b9b-b5b9-12e1e330a9c0_5914011.mp4?rel=0" type="video/mp4" />
              </video>
              <video className={welcomeStyles.iframe}  controls="true">
                <source src="https://media.sparemin.com/embed-video/videos/3e/d8/84/1f/3ed8841f-2b58-49b3-a9b3-faf03e39cbce_5801630.mp4?rel=0" type="video/mp4" />
              </video>
              <video className={welcomeStyles.iframe}  controls="true">
                <source src="https://media.sparemin.com/embed-video/videos/c4/bc/86/05/c4bc8605-2f87-40ef-b270-7f2f97785d0d_5688937.mp4?rel=0" type="video/mp4" />
              </video>
            
              <video className={welcomeStyles.iframe}  controls="true">
                <source src="https://media.sparemin.com/embed-video/videos/5c/4b/25/3d/5c4b253d-66f0-4976-9d55-35e6e6789ec6_5457269.mp4?rel=0" type="video/mp4" />
              </video>

              <video className={welcomeStyles.iframe}  controls="true">
                <source src="https://media.sparemin.com/embed-video/videos/d9/bd/01/b4/d9bd01b4-41b1-4957-ac3f-a4683b036ccb_5457155.mp4?rel=0" type="video/mp4" />
              </video>
                
              <video className={welcomeStyles.iframe}  controls="true">
                <source src="https://media.sparemin.com/embed-video/videos/98/cc/c4/3c/98ccc43c-99bc-4868-946e-349a9faa8f70_5574969.mp4?rel=0" type="video/mp4" />
              </video>      
                          
            </div>
         </div>           

        </div>

        </div>
      
    
      

      </section>
      </section>

      
	</section>
	 
  )
}

export default Podcast;
